<template>
  <div class="searchPage" :class="{ wholesale: isWholesale }">
    <div v-if="isWholesale && !hideBanner">
      <WholesaleBanner />
    </div>
    <seez-sdk-search @track="e => $root.trackEvent(e)" :style="cardStyles" :to="listingRoute" :filters="urlFilterString" @filters-updated="filtersUpdated" v-if="urlFilterString != null" @select="selected" @click-payment="e => clickPayment(e)" @total-results="onTotalResults" :focus-id="focusId" @body-style-change="onBodyStyleChange" :placeholder="$root.cardPlaceholder" :heading-s-e-o="headingText" :page-link-url="paginationUrl" ref="search" lg="da">
      <div class="bannerLinkWrapper" slot="underfilter">
        <Banner-link v-for="item in bannerData" :key="item.id" :id="item.id" :show="item.show" :content="item.content" :theme="item.theme" :to="item.href" />
      </div>
      <div class="leadFormWrapper" slot="empty">
        <seez-sdk-lead-form lg="da">
          <h2 slot="heading">{{ leadData.heading }}</h2>
          <p slot="intro" class="leadFormIntroText">{{ leadData.mainText }}</p>
          <p slot="comments" class="leadFormCommentsText">{{ leadData.commentText }}</p>
        </seez-sdk-lead-form>
      </div>
    </seez-sdk-search>
    <div v-if="urlFilterString != null" class="infoWrapper">
      <div />
      <div>
        <p class="info">* Der tages forbehold for tastefejl og udsolgte varer.</p>
        <div v-if="showSEOImage || showSEOText" class="bannerSEO">
          <div v-if="showSEOImage" class="imageWrapper">
            <img v-if="$root.dynamicData?.banner_image" :src="imageUrl" />
            <div>
              <h3>{{ $root.dynamicData?.banner_title[0].text }}</h3>
              <a :href="$root.dynamicData?.banner_button_link.url">{{ $root.dynamicData?.banner_button_label[0].text }}</a>
            </div>
          </div>

          <ul v-if="showSEOText">
            <li v-for="item in $root.dynamicData.paragraphs" :key="item.title">
              <h3>{{ item.title }}</h3>
              <p v-html="serializeHtml(item.text[0])" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ROUTES } from '../../router/index'
import BannerLink from './BannerLink.vue'
import WholesaleBanner from '../../components/WholesaleBanner.vue'

const bannerLinkData = [
  {
    id: 'models-guide',
    content: {
      heading: 'Få besked om de nyeste tilføjelser',
      mainText: 'Bliv blandt de allerførste til at få besked, når nye afhentningsbiler bliver tilføjet/oprettet.',
      linkText: 'Tilmeld nyhedsbrev'
    },
    theme: {
      background_color: '#FFCE01',
      color: 'black',
      link_color: 'black'
    },
    href: '/engros/tilmeld/'
  },
  {
    id: 'wholesale',
    theme: {
      background_color: '#FECE00',
      color: 'black',
      link_color: 'black'
    },
    content: {
      heading: 'Søg afhentningsbiler',
      mainText: 'Afhentningsbiler sælges til virksomheder med CVR-nummer. Sælges uden klargøring, reklamationsret og garanti.',
      linkText: 'Se udvalg'
    },
    href: '/soegeresultater/engros'
  },
  {
    id: 'retail',
    theme: {
      background_color: '#5BC2F4',
      color: 'black',
      link_color: 'black'
    },
    content: {
      heading: 'Søg detailbiler',
      mainText: 'Er du på udkig efter en af vores topklargjorte biler, så søg i stedet blandt de hundredevis af nyere brugte Mercedes-Benz.',
      linkText: 'Se udvalge'
    },
    href: '/soegeresultater'
  }
]

const colors = [
  { id: 'grey', color: '#757575', da: 'grå' },
  { id: 'silver', color: '#E2E2E2', da: 'sølv' },
  { id: 'white', color: '#FFFFFF', da: 'hvid' },
  { id: 'black', color: '#000000', da: 'sort' },
  { id: 'blue', color: '#0068FF', da: 'blå' },
  { id: 'red', color: '#FF0009', da: 'rød' },
  { id: 'orange', color: '#FFA500', da: 'orange' },
  { id: 'brown', color: '#664E00', da: 'brun' },
  { id: 'green', color: '#009A33', da: 'grøn' },
  { id: 'yellow', color: '#FFD500', da: 'gul' }
]

export default {
  name: 'SearchPage',
  components: { BannerLink, WholesaleBanner },
  data() {
    return {
      filterOptions: null,
      urlFilterString: null,
      documentName: '',
      headingText: '',
      showLeadModal: false,
      details: null,
      totalResults: null,
      hideBanner: false
    }
  },
  computed: {
    focusId() {
      if (document.referrer == null) return null
      const pattern = /\/(\d+)\/?$/g
      const match = pattern.exec(document.referrer)
      if (match == null) return null
      return match[1]
    },
    displaySEO() {
      return this.showSEOImage || this.showSEOText ? 'none' : 'block'
    },
    leadData() {
      if (this.totalResults === 0) {
        return {
          heading: 'Undskyld, det kunne vi ikke finde – lige nu',
          mainText: 'Men det betyder ikke, at vi ikke kan hjælpe dig. Vi har altid mange biler på vej ind, eller kørende i vores flåde. Derfor er du meget velkommen til at kontakte os her eller på telefonen med dine ønsker. Så skal vi nok hjælpe dig med at finde den bil, du søger.',
          commentText: 'Fortæl os, hvad du søger.'
        }
      }
      return {}
    },
    policyUrl() {
      return '/persondata-politik'
    },
    showSEOImage() {
      return this.$root.dynamicData && Object.keys(this.$root.dynamicData.banner_image).length > 0
    },
    showSEOText() {
      return this.$root.dynamicData && this.$root.dynamicData?.paragraphs.length > 1
    },
    imageUrl() {
      return this.$root.dynamicData?.banner_image?.url
    },
    cardStyles() {
      return {
        '--card-height': this.isWholesale ? '23.25rem' : '27.5rem',
        '--card-financing-display': this.isWholesale ? 'none' : 'flex',
        '--card-leasing-display': this.isWholesale ? 'none' : 'flex'
      }
    },
    isWholesale() {
      if (['engros', 'wholesale'].includes(this.$route.params.brand)) return true
      if (['wholesale', 'wholesaleLease'].includes(this.$route.query['price-type'])) return true
      return false
    },
    listingRoute() {
      return `${ROUTES.LISTING}/{brand}/{model}-{variant}/{id}/`
    },
    bannerData() {
      if (!this.isWholesale) {
        return bannerLinkData.filter(item => item.id !== 'retail')
      }
      return bannerLinkData.filter(item => item.id !== 'wholesale')
    },
    paginationUrl() {
      const q = new URLSearchParams(this.$route.query)
      q.set('page', '{index}')
      return this.$route.path + '?' + decodeURIComponent(q.toString())
    }
  },
  watch: {
    totalResults(totalResults) {
      if (this.headingText) {
        this.headingText = `${this.headingText} (${totalResults})`
      }
      if (totalResults === 0) {
        this.details = null
        this.showLeadModal = true
      } else {
        this.$nextTick(() => {
          this.showLeadModal = false
        })
      }
    },
    showLeadModal() {
      this.$root.track(this.$root.events.CTA_CLICK, { properties: { event_label: 'show_lead_modal' } })
    },
    urlFilterString() {
      this.$nextTick(() => {
        const searchElement = this.$refs.search
        this.setDisabledLeaseBtn(searchElement)
      })
    }
  },
  mounted() {
    this.loadFilterOptions().then(this.parseUrl)
  },
  methods: {
    /**
     * Disable the #lease-btn for wholesale pages
     * @param {*} searchElement
     */
    setDisabledLeaseBtn(searchElement) {
      if (this.isWholesale && searchElement) {
        // Iterate through the search container nodes to find the lease button and attach a disabled attribute to it
        // in order to stop the click event and adjust the style for the disabled state
        searchElement?.shadowRoot?.childNodes.forEach(node => {
          if (node.getAttribute('id') === 'search') {
            if (node.querySelector('#lease-btn')) {
              node.querySelector('#lease-btn').setAttribute('disabled', 'disabled')
            }
          }
        })
      }
    },
    onBodyStyleChange(v) {
      const value = v.detail[0]
      if (value) document.body.style.height = '100vh'
      else document.body.style.height = 'auto'
      this.hideBanner = value
    },
    onTotalResults(e) {
      this.totalResults = e.detail[0].totalResults
    },
    routeToListing(listing, scroll = false) {
      const { model, variant } = listing
      const slugify = text => this.$root.slugify(text)

      const family = `${slugify(model.name)}-${slugify(variant)}`
      const hash = scroll ? { hash: '#calc' } : {}
      const path = `/biler/${slugify(model.family.brand.name)}/${family}/${listing.id}`

      this.$router.push({ path, ...hash })
    },
    selected(e) {
      e.detail[0].preventDefault()
      const listing = e.detail[1].item
      this.routeToListing(listing)
    },
    clickPayment(e) {
      const selectedPaymentType = e.detail[0].type
      const listing = e.detail[0].listing
      this.details = listing

      if (selectedPaymentType === 'financing') {
        const scrollToFinance = true
        this.routeToListing(listing, scrollToFinance)
      }

      this.showLeadModal = true
    },
    serializeHtml({ spans, text }) {
      if (text && spans.length > 0) {
        let fullString = ''
        for (let i = 0; i < spans.length; i++) {
          const start = spans[i].start
          const end = spans[i].end
          const url = spans[i].data.url
          const textToSearch = fullString ? fullString : text
          const searchString = text.substring(start, end)
          const newLink = `<a href="${url}">${searchString}</a>`
          const final = textToSearch.replace(searchString, newLink)
          fullString = final
        }
        return fullString
      }
      return text
    },
    async loadFilterOptions() {
      const response = await window.seezSdk.queryApi('query filterOptionsWithCounters($filter: ListingFiltersInput){filterOptionsWithCounters(filter:$filter){brands{id,name,count},families{id,name,brandid,count},models{id,name,familyid,count},fuels{id,name,count},bodies{id,name,count},transmissions{id,name,count},years{year,count},numbersOfDoors{number_of_doors,count},driveTypes{drive_type,count},engineSizes{engine_size,count},colors{color,count}}}', { filter: { prices: [{ type: 'wholesale' }, { type: 'wholesaleLease' }, { type: 'retail' }, { type: 'wholesale' }, { type: 'lease' }] } })
      this.filterOptions = response.filterOptionsWithCounters
    },
    async parseUrl() {
      const result = new URLSearchParams(this.$route.fullPath.substring(this.$route.path.length))
      this.documentName = this.$route.params.something
      this.parseFirstPath(this.$route.params.brand, result)
      this.parseSecondPath(this.$route.params.something, result)
      this.normalizeQuery(result, this.$route.params.brand, this.$route.params.something)
      this.urlFilterString = result.toString()
      this.updateMetadata()
    },
    parseFirstPath(path, finalUrl) {
      const wholesale = finalUrl.has('price-type') && finalUrl.get('price-type') === 'wholesale'
      if (!path && !wholesale) {
        const mercedes = this.filterOptions.brands.find(b => this.$root.slugify(b.name) === 'mercedes')
        finalUrl.set('brands', mercedes.id)
      }
      if (path === 'engros') {
        this.documentName = path
        finalUrl.set('price-type', 'wholesale')
      } else if (path === 'leasingbiler') {
        finalUrl.set('price-type', 'lease')
        this.documentName = path
      } else if (path === 'mercedes-benz-certified') {
        this.documentName = path
        finalUrl.set('certified', 'true')
      } else {
        const brandId = this.filterOptions.brands.find(b => this.$root.slugify(b.name) === path.toLowerCase())?.id
        if (brandId) finalUrl.set('brands', brandId)
      }
    },
    parseSecondPath(path, finalUrl) {
      if (['amg'].includes(path)) {
        const AMG = 'amg'
        this.documentName = AMG
        finalUrl.set('tags', AMG)
        return
      }
      const color = colors.find(c => this.$root.slugify(c.da) === this.$root.slugify(path))
      if (color) {
        finalUrl.set('colors', color.id)
        return
      }
      const transmission = this.filterOptions.transmissions.find(t => this.$root.slugify(t.name) === path.toLowerCase())
      if (transmission) {
        finalUrl.set('transmissions', transmission.id)
        return
      }
      if (['automatgear'].includes(path)) {
        const KEY_AUTOMATIC = 'automatisk'
        const transmission = this.filterOptions.transmissions.find(t => this.$root.slugify(t.name) === KEY_AUTOMATIC)
        finalUrl.set('transmissions', transmission.id)
        return
      }
      if (['EQE'].includes(path)) {
        const EQE_FAMILY_NAME = 'eqe-klasse'
        this.documentName = EQE_FAMILY_NAME
        const family = this.filterOptions.families.find(m => this.$root.slugify(m.name) === EQE_FAMILY_NAME)
        if (family.length === 0) return
        finalUrl.set('families', family.id)
      }
      if (['ml-gle-klasse'].includes(path)) {
        const GLE_FAMILY_NAME = 'gle-klasse'
        this.documentName = GLE_FAMILY_NAME
        const family = this.filterOptions.families.find(m => this.$root.slugify(m.name) === GLE_FAMILY_NAME)
        if (family.length === 0) return
        finalUrl.set('families', family.id)
      }
      if (['elbil'].includes(path)) {
        const KEY_ELECTRIC = 'el'
        const fuelType = this.filterOptions.fuels.find(f => this.$root.slugify(f.name) === KEY_ELECTRIC)
        finalUrl.set('fuel-types', fuelType.id)
        return
      }
      const bodyType = this.filterOptions.bodies.find(b => this.$root.slugify(b.name) === path.toLowerCase())
      if (bodyType) {
        finalUrl.set('body-types', bodyType.id)
        return
      }
      const fuelType = this.filterOptions.fuels.find(f => this.$root.slugify(f.name) === path.toLowerCase())
      if (fuelType) {
        finalUrl.set('fuel-types', fuelType.id)
        return
      }
      const family = this.filterOptions.families.find(f => this.$root.slugify(f.name) === path.toLowerCase())
      if (family) {
        finalUrl.set('families', family.id)
        return
      }
      const models = path.length >= 4 ? this.filterOptions.models.filter(m => this.$root.slugify(m.name).startsWith(path.toLowerCase())) : [this.filterOptions.models.find(m => this.$root.slugify(m.name) === path.toLowerCase())].filter(x => x != null)
      if (models?.length > 0) {
        finalUrl.set('models', models.map(m => m.id).join(','))
        return
      }
    },
    normalizeQuery(finalUrl, firstPath) {
      // Default sort by created_date: '?sort=created_date', from oldest create date to the newest created date
      if (!finalUrl.has('sort')) {
        finalUrl.set('sort', 'created_date')
      }

      // console.log([...finalUrl.keys()])
      if (finalUrl.has('retail_price_max')) {
        finalUrl.set('price-max', finalUrl.get('retail_price_max'))
        finalUrl.set('price-type', 'retail')
        finalUrl.delete('retail_price_max')
      }

      if (finalUrl.has('retail_price_min')) {
        finalUrl.set('price-min', finalUrl.get('retail_price_min'))
        finalUrl.set('price-type', 'retail')
        finalUrl.delete('retail_price_min')
      }

      if (finalUrl.has('monthly_price_max')) {
        finalUrl.set('price-max', finalUrl.get('monthly_price_max'))
        finalUrl.set('price-type', firstPath == 'leasingbiler' ? 'lease' : 'emi')
        finalUrl.delete('monthly_price_max')
      }

      if (finalUrl.has('monthly_price_min')) {
        finalUrl.set('price-min', finalUrl.get('monthly_price_min'))
        finalUrl.set('price-type', firstPath == 'leasingbiler' ? 'lease' : 'emi')
        finalUrl.delete('monthly_price_min')
      }

      if (finalUrl.has('lease_price_max')) {
        finalUrl.set('price-max', finalUrl.get('lease_price_max'))
        finalUrl.set('price-type', firstPath == 'leasingbiler' ? 'lease' : 'emi')
        finalUrl.delete('lease_price_max')
      }

      if (finalUrl.has('lease_price_min')) {
        finalUrl.set('price-min', finalUrl.get('lease_price_min'))
        finalUrl.set('price-type', firstPath == 'leasingbiler' ? 'lease' : 'emi')
        finalUrl.delete('lease_price_min')
      }

      const keyRenames = {
        fuel_type_ids: 'fuel-types',
        make_ids: 'brands',
        year_min: 'year-min',
        year_max: 'year-max',
        model_family_ids: 'families',
        kilometrage_max: 'kilometrage-max',
        body_type_ids: 'body-types',
        exterior_color_ids: 'colors'
      }

      if (finalUrl.has('model_ids')) {
        finalUrl.set('models', finalUrl.get('model_ids'))
        finalUrl.delete('model_ids')
      }

      for (const key in keyRenames) {
        if (finalUrl.has(key)) {
          finalUrl.set(keyRenames[key], finalUrl.get(key))
          finalUrl.delete(key)
        }
      }

      let keysToDel = []
      finalUrl.forEach((value, key) => {
        if (value == '') {
          keysToDel.push(key)
        }
      })
      keysToDel.forEach(key => {
        finalUrl.delete(key)
      })

      if (finalUrl.has('sort')) {
        const sortValue = finalUrl.get('sort').split(':')
        const desc = sortValue?.[1] === 'desc' ? '-' : ''
        const sortRenames = {
          original_created_date: 'created_date',
          kilometrage: 'mileage'
          // registration_date: 'registration-date'?
        }

        finalUrl.set('sort', desc + (sortRenames[sortValue[0]] ?? sortValue[0]))
      }
    },
    cleanFilter(filter) {
      const cleanFilters = {}
      for (const key in filter) {
        if (filter[key] == null || filter[key] === '') continue
        const k = key.replace(/([A-Z])/g, r => '-' + r.toLowerCase())
        if (Array.isArray(filter[key])) cleanFilters[k] = filter[key].join(',')
        else cleanFilters[k] = filter[key].toString()
      }
      if (cleanFilters.prices) {
        const fp = filter.prices[0]
        cleanFilters['price-type'] = fp.type

        if (fp.maxPrice > 0) cleanFilters['price-max'] = fp.maxPrice
        if (fp.minPrice > 0) cleanFilters['price-min'] = fp.minPrice

        delete cleanFilters.prices
      }
      if (cleanFilters['number-of-doors-min'] && cleanFilters['number-of-doors-max'] && cleanFilters['number-of-doors-min'] === cleanFilters['number-of-doors-max']) {
        cleanFilters.doors = cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-min']
        delete cleanFilters['number-of-doors-max']
      }
      if (cleanFilters.sort === '-attractiveness') delete cleanFilters.sort
      return cleanFilters
    },
    filtersUpdated(v) {
      let cleanFilters = this.cleanFilter(v.detail[0])
      const value = Object.values(this.$route.query)

      if (cleanFilters.engros) {
        delete cleanFilters.engros
      }
      if (cleanFilters['filter-open']) {
        delete cleanFilters['filter-open']
      }

      if (value.includes('wholesale')) {
        cleanFilters = { ...cleanFilters, 'price-type': 'wholesale' }
      }

      const route = { path: '/soegeresultater', query: cleanFilters }

      if (this.filterOptions && cleanFilters.brands && !cleanFilters.brands.includes(',')) {
        const brand = this.filterOptions.brands.find(b => b.id === cleanFilters.brands)
        route.path += `/${this.$root.slugify(brand.name)}`
        delete cleanFilters.brands

        if (cleanFilters.colors) {
          this.headingText = ''
          const color = colors.find(c => c.id === cleanFilters.colors)
          if (!color) {
            route.query = { colors: cleanFilters.colors }
          } else {
            route.path += `/${color.da}`
          }
          delete cleanFilters.colors
        } else if (cleanFilters.families && !cleanFilters.families.includes(',')) {
          const family = this.filterOptions.families.find(f => f.id === cleanFilters.families)
          route.path += `/${this.$root.slugify(family.name)}`
          delete cleanFilters.families
        } else if (cleanFilters.models && !cleanFilters.models.includes(',')) {
          const model = this.filterOptions.models.find(m => m.id === cleanFilters.models)
          route.path += `/${this.$root.slugify(model.name)}`
          delete cleanFilters.models
        }
      }
      if (!cleanFilters.brands) {
        this.headingText = ''
        this.$root.setPageTitle()
        this.$root.dynamicData = null
      }
      if (cleanFilters.tags) {
        route.path += `/${cleanFilters.tags}`
        delete cleanFilters.tags
        delete cleanFilters.sort
      }

      this.$router.replace(route)
    },
    async updateMetadata() {
      if (this.$route.params.brand) {
        const data = await this.getBrandSeo()
        this.$root.setPageTitle(data.title, data.description)
      } else {
        this.$root.setPageTitle()
      }
    },
    async getBrandSeo() {
      const brand = this.filterOptions.brands.find(b => this.$root.slugify(b.name) === this.$route.params.brand.toLowerCase())
      const documentSlug = this.$root.slugify(this.documentName.toLowerCase())
      const document = await this.$root.getDynamicContent('search_content', documentSlug)

      if (document && document.data?.search_title[0].text) {
        this.headingText = `${document.data.search_title[0].text}`
      }

      if (!document) {
        const seo = { default: { title: 'Brugte {brand}', description: 'Brugte {brand} til salg » Stort udvalg af brugte biler' } }

        return {
          title: seo.default.title.replace('{brand}', brand.name),
          description: seo.default.description.replace('{brand}', brand.name)
        }
      }

      return { title: document.data.meta_title[0].text, description: document.data.meta_description[0].text }
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/base.scss';

// start styles for the seez-sdk-lead-modal
seez-sdk-lead-modal {
  @include starmark-lead-modal-style;
}
// end styles for seez-sdk-modal

seez-sdk-lead-form {
  @include starmark-lead-form;

  .info {
    display: none;
  }

  .leadForm {
    margin: 1rem auto 0;

    .formWrapper {
      box-shadow: 0 0 4px #b3b3b380;
      border-radius: 0.75rem;
    }
  }

  .leadFormIntroText {
    margin: 1.5em 0;
  }

  .leadFormCommentsText {
    grid-column: 1 / -1;
  }
}

.searchPage {
  &.wholesale {
    .financingPriceWrapper {
      border-bottom: 0;
    }
    .leasingPriceWrapper {
      display: none;
    }
  }
  margin-top: 5.5rem;
  min-height: 100vh;
  @include respond(st-48) {
    margin-top: 0;
  }

  .infoWrapper {
    margin-bottom: 1rem;
    display: grid;

    @include respond(st-48) {
      grid-template-columns: 38ch 5fr;
    }
    .info {
      text-align: center;
      color: #666666;
      font-family: var(--font-family);
      margin-top: 1rem;
    }

    .bannerSEO {
      padding: 2rem 1rem;
      > .imageWrapper {
        position: relative;

        > img {
          max-height: 15.625rem;
          width: 100%;
          object-fit: cover;
          min-height: 15rem;
        }

        > div {
          position: absolute;
          top: 40%;
          left: 10%;
          display: flex;
          flex-direction: column;
          font-size: 1.7777777777777777rem;
          font-family: var(--font-heading);

          h3 {
            text-decoration: none;
            margin-bottom: 1rem;
            color: white;
          }

          a {
            background-color: var(--highlight);
            border-radius: 12px;
            padding: 0.8rem 0.5rem;
            color: white;
            max-width: 130px;
            margin: auto 0;
            text-transform: uppercase;
            font-size: 1rem;
            text-decoration: none;
            font-family: var(--font-family);
            text-align: center;

            &:hover {
              background-color: #757575;
            }
          }
        }
      }

      > ul {
        > li {
          margin: 1rem 0;
          > h3 {
            font-family: var(--font-heading);
            font-size: 32px;
            color: black;
          }

          > p {
            font-family: var(--font-family);
            font-size: 1rem;

            > a {
              color: var(--highlight);
            }
          }
        }
      }
    }
  }

  // .wholesaleInfo {
  //   background-color: #fece00;
  //   font-size: 0.875rem;
  //   padding: 0.875rem;
  //   text-align: center;
  // }

  .bannerLinkWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    margin-bottom: 1rem;

    &:first-child {
      margin-top: 1rem;
    }
  }
}

seez-sdk-search {
  #search {
    * {
      font-family: var(--font-family);
      --highlight: #5bc2f4;
    }

    h1 {
      font-family: var(--font-family);
    }

    input {
      font-size: 1rem;
    }

    grid-template-columns: auto;

    @include respond(st-48) {
      grid-template-columns: 21rem 5fr;
    }

    .side {
      padding: 0 1rem;
      order: 2;
      margin-bottom: 1rem;

      @include respond(st-48) {
        order: 0;
        margin-bottom: 0;
      }
    }

    .resultsSection {
      min-height: 100vh;
      grid-template-columns: 1fr;
      grid-auto-rows: repeat(3, auto);

      .SEOText {
        margin-inline-start: 1rem;
        font-family: var(--font-family);
        margin-bottom: 1.5rem;

        @media screen and (max-width: 47.938rem) {
          font-size: 1.3rem;
          margin: 0.4rem 0rem 0.4rem 1rem;
        }
      }

      h1 {
        margin-top: 1rem;
        @include respond(st-48) {
          margin-top: 1.5rem;
        }
      }

      .filterTags {
        padding: 0 !important;
        > span {
          background: rgba(91, 194, 244, 0.08);
        }
      }
      > .resultsList {
        margin-bottom: 4rem;
        @include respond(st-48) {
          margin-bottom: 0;
        }
        .SEO {
          display: v-bind('displaySEO');
        }
        > .emptySearch > div {
          svg > path {
            fill: var(--highlight);
          }
        }
        .listingsList {
          // grid-template-rows: repeat(auto-fill, minmax(440px, 1fr));
          // grid-template-rows: repeat(auto-fill, minmax(var(--card-height), 1fr));

          @media screen and (min-width: 31.25rem) {
            // grid-template-rows: repeat(auto-fill, minmax(var(--card-height), 0fr));
            grid-template-columns: repeat(auto-fill, minmax(20.5em, 1fr));
          }
        }
        .listingCard {
          @include starmark-listing-card(var(--card-height), var(--card-financing-display), var(--card-leasing-display));
        }
      }

      > .sorting {
        button:first-of-type {
          border-radius: 12px;
          font-family: var(--font-family);
        }
        .multiselect {
          border: 1px solid #b4b4d0;
          border-radius: 12px !important;
          padding: 0 !important;

          button {
            font-family: var(--font-family);
            background-color: var(--light-gray);
            border: none;
            height: 100%;
            padding: 0 1rem;
            margin: 0;
            font-size: 1rem;

            .selected {
              padding-inline-end: 1rem;
            }

            @include respond(st-48) {
              padding: 0.75em 2.75em 0.75em 1.75em;
            }

            svg {
              transform: translateY(0.2rem);

              @include respond(st-48) {
                transform: translateY(-0.1rem);
              }
            }
          }
        }
      }
    }
    //sideFilter component class
    .filtersPanel {
      background-color: var(--light-gray);
      border: none;
      margin-bottom: 1rem;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;

      .searchFooter {
        background-color: var(--light-gray);
        > button {
          border-radius: 12px;
        }
      }

      .modalSearchName {
        button {
          border-radius: 12px;
        }
        button:not(button[disabled]) {
          cursor: pointer;
        }
      }

      .fixedHeading {
        background-color: var(--light-gray);

        > div .clearFilters {
          font-size: 1rem;

          svg > path {
            fill: var(--highlight);
          }
          color: var(--highlight);
        }
      }

      .yearWrapper {
        * {
          font-size: 1rem;
        }
      }

      .headingWrapper {
        h3 {
          font-size: 1rem;
        }

        svg {
          > path {
            fill: black;
          }
          > circle {
            fill: black;
          }
        }
      }

      .priceWrapper {
        .purchaseOptions {
          button {
            border-radius: 12px;
            background-color: white;
            border: 2px solid #e2e2e2;
            font-size: 1rem;
            padding: 0.8rem;
          }

          button#lease-btn[disabled='disabled'] {
            cursor: not-allowed;
            border-color: #e2e2e2;
            color: #afafaf;
            background: #fff;
          }

          > .active {
            background: rgba(91, 194, 244, 0.4);
            border: 2px solid #5bc2f4;
            font-weight: 700;
          }
        }

        .paymentTypes {
          * {
            font-size: 1rem;
          }
          > div {
            background-color: white;
            border: none;
          }
        }

        .prices > div > label {
          padding: 0;
          margin: 0;
          font-size: 1rem;
        }

        .prices > div > input {
          border: none;
        }
      }
      input {
        background: #ffffff;
        border: none;
        border-radius: 12px;
        padding: 0.8rem;
        font-size: 1rem;
      }

      input:first-of-type {
        border: 1px solid #757575;
      }

      #priceTypeSelector {
        > button {
          background-color: #5bc2f4;
          border-radius: 12px 0px 0px 12px;
        }
      }

      #priceSelector {
        border-radius: 0px 12px 12px 0px;
        padding: 1em 1em 1em 1em;
      }

      .multiselect {
        > button {
          border-radius: 12px;
          border: none;
          text-indent: 0.625rem;
          border: 1px solid #eeeeee;
          border-radius: 12px;
          padding: 0.8rem;
          font-size: 1rem;

          svg {
            transform: translateY(0.2rem);

            @include respond(st-48) {
              transform: translateY(0.1rem);
            }
          }
        }
      }

      .saveSearch {
        background-color: var(--highlight);
        border-radius: 12px;
        color: white;
        font-size: 1rem;
        padding: 0.8rem;
      }

      .saveSearch[disabled] {
        opacity: 0.5;
      }
    }
  }
}

.leadFormWrapper {
  display: flex;
  justify-content: center;
}
</style>
